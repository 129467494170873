import { render, staticRenderFns } from "./UserProfile.vue?vue&type=template&id=f2de7bfa&scoped=true&"
import script from "./UserProfile.vue?vue&type=script&lang=ts&"
export * from "./UserProfile.vue?vue&type=script&lang=ts&"
import style0 from "./UserProfile.vue?vue&type=style&index=0&id=f2de7bfa&prod&lang=postcss&scoped=true&"
import style1 from "vue-phone-number-input/dist/vue-phone-number-input.css?vue&type=style&index=1&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f2de7bfa",
  null
  
)

export default component.exports